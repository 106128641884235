import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
var ForgotPasswordComponent = /** @class */ (function () {
    function ForgotPasswordComponent(agencyService, userService, authService, loaderService, router, snackBar) {
        this.agencyService = agencyService;
        this.userService = userService;
        this.authService = authService;
        this.loaderService = loaderService;
        this.router = router;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.success = false;
        this.snackBarConfig = {
            panelClass: 'popup-message',
            duration: 10000,
            verticalPosition: 'top'
        };
        this.messages = [
            { message: 'Oops!! You do not seem to be part of this agency.', action: 'Request to join' },
        ];
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.agencySubscription = this.agencyService.getCurrentAgency().subscribe(function (agency) {
            _this.agency = agency;
            _this.displayURI = "" + _this.baseURL + _this.agency.workspaceURI;
        });
    };
    ForgotPasswordComponent.prototype.ngOnDestroy = function () {
        this.agencySubscription.unsubscribe();
    };
    ForgotPasswordComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Show the loader
                        this.loaderService.isLoading.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.userService.getUserByEmail(this.email)];
                    case 2:
                        user = _a.sent();
                        if (!(user.agencyId === this.agency.uid)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.authService.resetPassword(this.email)];
                    case 3:
                        _a.sent();
                        // Redirect to the success page
                        this.success = true;
                        return [3 /*break*/, 5];
                    case 4:
                        this.loaderService.isLoading.next(false);
                        this.snackBar.open(this.messages[0].message, this.messages[0].action, this.snackBarConfig);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        this.loaderService.isLoading.next(false);
                        this.snackBar.open(err_1.message, 'OK', this.snackBarConfig);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    ForgotPasswordComponent.prototype.onBack = function () {
        this.router.navigate(["/" + this.agency.workspaceURI, 'login']);
    };
    return ForgotPasswordComponent;
}());
export { ForgotPasswordComponent };
