import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Validators } from '@angular/forms';
import { MustMatch } from '../../validators/auth.validator';
var UpdatePasswordComponent = /** @class */ (function () {
    function UpdatePasswordComponent(formBuilder, agencyService, authService, userService, loaderService, router, snackBar) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.agencyService = agencyService;
        this.authService = authService;
        this.userService = userService;
        this.loaderService = loaderService;
        this.router = router;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.disabled = true;
        this.snackBarConfig = {
            panelClass: 'popup-message',
            duration: 10000,
            verticalPosition: 'top'
        };
        this.agencyService.agency$.subscribe(function (agency) {
            _this.agency = agency;
            _this.displayURI = "" + _this.baseURL + _this.agency.workspaceURI;
        });
    }
    UpdatePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Get the user full name
        this.userService.getUserByEmail(this.email).then(function (user) {
            _this.fullName = user.firstName + " " + user.lastName;
        });
        this.updatePasswordForm = this.formBuilder.group({
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
        // Enable CTA on values matching
        this.enableCTA(this.f.password.valueChanges, this.f.confirmPassword.valueChanges);
    };
    Object.defineProperty(UpdatePasswordComponent.prototype, "f", {
        get: function () { return this.updatePasswordForm.controls; },
        enumerable: true,
        configurable: true
    });
    /**
     * Enable the CTA button when the password and confirm
     * password values match.
     *
     * @private
     * @param {Observable<string>} password
     * @param {Observable<string>} confirmPassword
     * @memberof UpdatePasswordComponent
     */
    UpdatePasswordComponent.prototype.enableCTA = function (password, confirmPassword) {
        var _this = this;
        var currentPasswordValue;
        password.subscribe(function (value) {
            currentPasswordValue = value;
        });
        confirmPassword.subscribe(function (value) {
            if (currentPasswordValue === value) {
                _this.disabled = false;
            }
            else {
                _this.disabled = true;
            }
        });
    };
    /**
     * Reset the user password and sign in to the app.
     *
     * @returns {Promise<void>}
     * @memberof UpdatePasswordComponent
     */
    UpdatePasswordComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var successMessage, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // stop here if form is invalid
                        if (this.updatePasswordForm.invalid) {
                            return [2 /*return*/];
                        }
                        // Disable the button
                        this.disabled = true;
                        // Show the loader
                        this.loaderService.isLoading.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        // Update the password
                        return [4 /*yield*/, this.authService.changePassword(this.email, this.oobCode, this.f.password.value)];
                    case 2:
                        // Update the password
                        _a.sent();
                        // Login
                        return [4 /*yield*/, this.authService.login(this.email, this.f.password.value, true)];
                    case 3:
                        // Login
                        _a.sent();
                        successMessage = "Welcome " + this.fullName + ", your password has been updated successfully.";
                        this.snackBar.open(successMessage, 'OK', this.snackBarConfig);
                        this.router.navigate([this.agency.workspaceURI]);
                        this.loaderService.isLoading.next(false);
                        return [3 /*break*/, 5];
                    case 4:
                        err_1 = _a.sent();
                        this.loaderService.isLoading.next(false);
                        this.snackBar.open(err_1.message, 'OK', this.snackBarConfig);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return UpdatePasswordComponent;
}());
export { UpdatePasswordComponent };
