import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var AgencyService = /** @class */ (function () {
    function AgencyService(afs) {
        this.afs = afs;
        this.agencyCollection = this.afs.collection('agency');
        var agency = JSON.parse(localStorage.getItem('agency'));
        this.agency$ = new Observable(function (observer) {
            if (agency && agency.workspaceURI) {
                observer.next(agency);
            }
            else {
                observer.next(null);
            }
        });
    }
    AgencyService.prototype.operator = function (operator) {
        if (operator === 'equalTo') {
            return '==';
        }
    };
    AgencyService.prototype.getCurrentAgency = function () {
        return this.agency$;
    };
    /**
     * Get the agency details by workspace URI.
     *
     * @param {string} workspaceURI
     * @returns {Promise<Agency>}
     * @memberof AgencyService
     */
    AgencyService.prototype.getByWorkspaceURI = function (workspaceURI) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var querySnapshot, err_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.afs.collection('agency', function (ref) { return ref.where('workspaceURI', '==', workspaceURI); }).get().toPromise()];
                                case 1:
                                    querySnapshot = _a.sent();
                                    if (querySnapshot.docs.length > 0) {
                                        querySnapshot.forEach(function (doc) {
                                            // Add local storage data
                                            var agency = tslib_1.__assign({ uid: doc.id }, doc.data());
                                            resolve(agency);
                                        });
                                    }
                                    else {
                                        reject({
                                            code: 'AGENCY_DOES_NOT_EXIST',
                                            message: "The workspace entered does not exist, if you cannot remember your workspace"
                                        });
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_1 = _a.sent();
                                    reject({
                                        code: err_1.statusCode,
                                        message: err_1.message
                                    });
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    AgencyService.prototype.query = function (data) {
        var _this = this;
        return this.afs.collection('agency', function (ref) {
            return ref.where(data.field, _this.operator(data.operator), data.value);
        }).get().toPromise();
    };
    AgencyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AgencyService_Factory() { return new AgencyService(i0.ɵɵinject(i1.AngularFirestore)); }, token: AgencyService, providedIn: "root" });
    return AgencyService;
}());
export { AgencyService };
