import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var ConfigService = /** @class */ (function () {
    function ConfigService(afs) {
        this.afs = afs;
    }
    ConfigService.prototype.operator = function (operator) {
        if (operator === 'equalTo') {
            return '==';
        }
    };
    ConfigService.prototype.query = function (data) {
        var _this = this;
        return this.afs.collection('config', function (ref) {
            return ref.where(data.field, _this.operator(data.operator), data.value);
        }).get().toPromise();
    };
    ConfigService.prototype.getConfig = function () {
        return this.afs.collection('config').doc(environment.id).get().toPromise();
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.AngularFirestore)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
