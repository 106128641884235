import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "../services/auth.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
        this.agency = JSON.parse(localStorage.getItem('agency'));
    }
    AuthGuard.prototype.canActivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.authService.isLoggedIn().subscribe(function (user) {
                            if (user) {
                                resolve(true);
                            }
                            else {
                                _this.router.navigate([_this.agency.workspaceURI, 'login']);
                                resolve(false);
                            }
                        });
                    })];
            });
        });
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
