import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
var WorkspaceComponent = /** @class */ (function () {
    function WorkspaceComponent(router, agencyService, loaderService, snackBar) {
        this.router = router;
        this.agencyService = agencyService;
        this.loaderService = loaderService;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.snackBarOptions = {
            panelClass: 'data-cy-snackbar',
            duration: 10000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
        };
    }
    WorkspaceComponent.prototype.ngOnInit = function () {
        var agency = JSON.parse(localStorage.getItem('agency'));
        if (agency && agency.workspaceURI && agency.workspaceURI !== '') {
            this.router.navigate([agency.workspaceURI, 'login']);
        }
    };
    WorkspaceComponent.prototype.goToLoginPage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var agency, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.disabled = true;
                        this.loaderService.isLoading.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.agencyService.getByWorkspaceURI(this.workspaceURI)];
                    case 2:
                        agency = _a.sent();
                        localStorage.setItem('agency', JSON.stringify(agency));
                        this.disabled = false;
                        this.loaderService.isLoading.next(false);
                        this.router.navigate([this.workspaceURI, 'login']);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        if (err_1.code === 'AGENCY_DOES_NOT_EXIST') {
                            this.snackBar.open(err_1.message, 'click here', this.snackBarOptions);
                        }
                        if (err_1.code !== 'AGENCY_DOES_NOT_EXIST') {
                            this.snackBar.open(err_1.message, '', this.snackBarOptions);
                        }
                        this.disabled = false;
                        this.loaderService.isLoading.next(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return WorkspaceComponent;
}());
export { WorkspaceComponent };
