import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Validators } from '@angular/forms';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, route, router, userService, authService, loaderService, snackBar) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.router = router;
        this.userService = userService;
        this.authService = authService;
        this.loaderService = loaderService;
        this.snackBar = snackBar;
        this.baseURL = environment.baseURL;
        this.submitted = false;
        this.hidePassword = true;
        this.snackBarConfig = {
            panelClass: 'popup-message',
            duration: 10000,
            verticalPosition: 'top'
        };
        this.messages = [
            { message: 'Oops!! You do not seem to be part of this agency.', action: 'Request to join' },
            { message: 'Please verify your email before signing in.', action: 'Resend email' },
            { message: '', action: '' },
        ];
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: false
        });
        this.agency = JSON.parse(localStorage.getItem('agency'));
        if (this.agency && this.agency.workspaceURI) {
            this.displayURI = "" + this.baseURL + this.agency.workspaceURI;
        }
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || "/" + this.agency.workspaceURI;
        // Forgot password router link
        this.forgotPasswordLink = "/" + this.agency.workspaceURI + "/forgot-password";
    };
    Object.defineProperty(LoginComponent.prototype, "f", {
        get: function () { return this.loginForm.controls; },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // stop here if form is invalid
                        if (this.loginForm.invalid) {
                            return [2 /*return*/];
                        }
                        // Show the loader
                        this.loaderService.isLoading.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, this.userService.getUserByEmail(this.f.email.value)];
                    case 2:
                        user = _a.sent();
                        if (!(user.agencyId === this.agency.uid)) return [3 /*break*/, 6];
                        if (!user.emailVerified) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.authService.login(this.f.email.value, this.f.password.value, this.f.rememberMe.value)];
                    case 3:
                        _a.sent();
                        this.snackBar.open("Welcome " + user.displayName, 'OK', { duration: 5000 });
                        this.router.navigate([this.returnUrl]);
                        this.loaderService.isLoading.next(false);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loaderService.isLoading.next(false);
                        this.snackBar.open(this.messages[1].message, this.messages[1].action, this.snackBarConfig);
                        _a.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        this.loaderService.isLoading.next(false);
                        this.snackBar.open(this.messages[0].message, this.messages[0].action, this.snackBarConfig);
                        _a.label = 7;
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        err_1 = _a.sent();
                        this.loaderService.isLoading.next(false);
                        this.snackBar.open(err_1.message, 'OK', this.snackBarConfig);
                        return [3 /*break*/, 9];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.onBack = function () {
        localStorage.removeItem('agency');
        this.router.navigate(['/']);
    };
    return LoginComponent;
}());
export { LoginComponent };
