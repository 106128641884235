import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
var UserService = /** @class */ (function () {
    function UserService(afs) {
        this.afs = afs;
        this.userCollection = this.afs.collection('users');
    }
    UserService.prototype.operator = function (operator) {
        if (operator === 'equalTo') {
            return '==';
        }
    };
    UserService.prototype.query = function (data) {
        var _this = this;
        return this.afs.collection('users', function (ref) {
            return ref.where(data.field, _this.operator(data.operator), data.value);
        }).get().toPromise();
    };
    UserService.prototype.getUserByEmail = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        var querySnapshot, err_1;
                        return tslib_1.__generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, this.afs.collection('users', function (ref) {
                                            return ref.where('email', '==', email);
                                        }).get().toPromise()];
                                case 1:
                                    querySnapshot = _a.sent();
                                    if (querySnapshot.docs.length > 0) {
                                        querySnapshot.forEach(function (doc) {
                                            var user = tslib_1.__assign({ uid: doc.id }, doc.data());
                                            resolve(user);
                                        });
                                    }
                                    else {
                                        reject({
                                            code: 'USER_DOES_NOT_EXIST',
                                            message: 'User does\'t exist on the platform'
                                        });
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_1 = _a.sent();
                                    reject(err_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.AngularFirestore)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
