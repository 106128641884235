import { Routes } from '@angular/router';
import { RegisterComponent } from './core/components/register/register.component';
import { LandingPage, LoginPage, ForgotPasswordPage, AuthPage } from './pages';
import { AuthGuard } from './core/guards/auth.guard';
var ɵ0 = function () { return import("./home/home.module.ngfactory").then(function (m) { return m.HomeModuleNgFactory; }); };
var routes = [
    { path: '', component: LandingPage },
    { path: 'register', component: RegisterComponent },
    { path: ':agency', children: [
            { path: 'login', component: LoginPage },
            { path: 'forgot-password', component: ForgotPasswordPage },
            { path: 'auth', component: AuthPage },
            {
                path: '',
                loadChildren: ɵ0,
                canActivate: [AuthGuard]
            }
        ] }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0 };
