import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/common/http";
var AuthService = /** @class */ (function () {
    function AuthService(afAuth, http) {
        this.afAuth = afAuth;
        this.http = http;
        this.user = afAuth.authState;
    }
    /**
     *
     *
     * @param {string} username
     * @param {string} password
     * @param {boolean} rememberMe
     * @returns {Promise<firebase.auth.UserCredential>}
     * @memberof AuthService
     */
    AuthService.prototype.login = function (username, password, rememberMe) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 6, , 7]);
                        if (!rememberMe) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(username, password)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [4 /*yield*/, this.afAuth.auth.setPersistence('session')];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.afAuth.auth.signInWithEmailAndPassword(username, password)];
                    case 4: return [2 /*return*/, _a.sent()];
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        err_1 = _a.sent();
                        throw err_1;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    /**
     *
     *
     * @returns {Observable<firebase.User>}
     * @memberof AuthService
     */
    AuthService.prototype.isLoggedIn = function () {
        return this.user;
    };
    /**
     *
     *
     * @returns {Promise<void>}
     * @memberof AuthService
     */
    AuthService.prototype.logout = function () {
        return this.afAuth.auth.signOut();
    };
    /**
     *
     *
     * @param {string} email
     * @returns {Promise<any>}
     * @memberof AuthService
     */
    AuthService.prototype.resetPassword = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, options;
            return tslib_1.__generator(this, function (_a) {
                url = environment.apiBaseURL + "/auth/password-reset?email=" + email;
                options = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json'
                    })
                };
                return [2 /*return*/, this.http.get(url, options).toPromise()];
            });
        });
    };
    /**
     *
     *
     * @param {string} email
     * @param {string} code
     * @param {string} newPassword
     * @returns {Promise<any>}
     * @memberof AuthService
     */
    AuthService.prototype.changePassword = function (email, code, newPassword) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, options, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = environment.apiBaseURL + "/auth/confirm?type=password-reset&email=" + email;
                        options = {
                            headers: new HttpHeaders({
                                'Content-Type': 'application/json'
                            })
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.afAuth.auth.confirmPasswordReset(code, newPassword)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, this.http.get(url, options).toPromise()];
                    case 3:
                        err_2 = _a.sent();
                        throw err_2;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.HttpClient)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
